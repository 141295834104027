// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-de-de-404-js": () => import("./../../../src/pages/de-de/404.js" /* webpackChunkName: "component---src-pages-de-de-404-js" */),
  "component---src-pages-en-au-404-js": () => import("./../../../src/pages/en-au/404.js" /* webpackChunkName: "component---src-pages-en-au-404-js" */),
  "component---src-pages-en-gb-404-js": () => import("./../../../src/pages/en-gb/404.js" /* webpackChunkName: "component---src-pages-en-gb-404-js" */),
  "component---src-pages-en-us-404-js": () => import("./../../../src/pages/en-us/404.js" /* webpackChunkName: "component---src-pages-en-us-404-js" */),
  "component---src-pages-es-es-404-js": () => import("./../../../src/pages/es-es/404.js" /* webpackChunkName: "component---src-pages-es-es-404-js" */),
  "component---src-pages-fr-fr-404-js": () => import("./../../../src/pages/fr-fr/404.js" /* webpackChunkName: "component---src-pages-fr-fr-404-js" */),
  "component---src-pages-it-it-404-js": () => import("./../../../src/pages/it-it/404.js" /* webpackChunkName: "component---src-pages-it-it-404-js" */),
  "component---src-pages-ja-jp-404-js": () => import("./../../../src/pages/ja-jp/404.js" /* webpackChunkName: "component---src-pages-ja-jp-404-js" */),
  "component---src-pages-ko-kr-404-js": () => import("./../../../src/pages/ko-kr/404.js" /* webpackChunkName: "component---src-pages-ko-kr-404-js" */),
  "component---src-pages-pt-br-404-js": () => import("./../../../src/pages/pt-br/404.js" /* webpackChunkName: "component---src-pages-pt-br-404-js" */),
  "component---src-pages-ru-ru-404-js": () => import("./../../../src/pages/ru-ru/404.js" /* webpackChunkName: "component---src-pages-ru-ru-404-js" */),
  "component---src-pages-tr-tr-404-js": () => import("./../../../src/pages/tr-tr/404.js" /* webpackChunkName: "component---src-pages-tr-tr-404-js" */),
  "component---src-pages-zh-cn-404-js": () => import("./../../../src/pages/zh-cn/404.js" /* webpackChunkName: "component---src-pages-zh-cn-404-js" */),
  "component---src-pages-zh-hant-404-js": () => import("./../../../src/pages/zh-hant/404.js" /* webpackChunkName: "component---src-pages-zh-hant-404-js" */),
  "component---src-templates-arena-news-js": () => import("./../../../src/templates/arena-news.js" /* webpackChunkName: "component---src-templates-arena-news-js" */),
  "component---src-templates-arena-news-web-js": () => import("./../../../src/templates/arena-news-web.js" /* webpackChunkName: "component---src-templates-arena-news-web-js" */),
  "component---src-templates-character-js": () => import("./../../../src/templates/character.js" /* webpackChunkName: "component---src-templates-character-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

