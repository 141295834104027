import { isValid } from 'date-fns'
import './src/styles/index.scss'
import { getRedirectLanguage } from './src/utils/getRedirectLanguages'
import { isValidLocaleCode } from './src/utils/isValidLocaleCode'
export const onClientEntry = () => {
    // TODO: remove on production
    return

    const browserLang = getRedirectLanguage()
    const pathName = window.location.pathname
    const pathSplit = pathName.split('/').filter((i) => i)
    //if the split pathname does not match any of the site locales,

    if (pathSplit[0] === 'news') {
        return
    }

    if (!isValidLocaleCode(pathSplit[0]) && browserLang === 'en') {
        return
    }

    //if browser Lang === pathname lang do nothing
    if (pathSplit[0] === browserLang) {
        return
    }

    //if browser is en and there is a locale in path, remove path locale
    if (browserLang === 'en' && isValidLocaleCode(pathSplit[0])) {
        pathSplit[0] = ''
        return (window.location.pathname = pathSplit.join('/'))
    }

    // if !en and !locale in path, add browser locale to path
    if (browserLang !== 'en' && !isValidLocaleCode(pathSplit[0])) {
        return (window.location.pathname = `/${browserLang}/${pathSplit.join(
            '/'
        )}`)
    }

    //if different locale is in path compared to browser lang, replace with browser lang
    if (
        isValidLocaleCode(pathSplit[0]) &&
        browserLang !== pathSplit[0] &&
        browserLang !== 'en'
    ) {
        pathSplit[0] = browserLang
        return (window.location.pathname = `/${pathSplit.join('/')}`)
    }

    //if none of the above conditions are met, do nothing
    return
}
