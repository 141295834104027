export const getRedirectLanguage = () => {
    if (typeof navigator === `undefined`) return 'en'

    const lang = navigator && navigator.language.toLowerCase()
    if (!lang) return 'en'
    switch (lang) {
        case 'en':
            return 'en'
        case 'en-au':
            return 'en-au'
        case 'en-us':
            return 'en-us'
        case 'de':
            return 'de-de'
        case 'ru':
            return 'ru-ru'
        case 'fr':
            return 'fr-fr'
        case 'es':
            return 'es-es'
        case 'it':
            return 'it-it'
        case 'pt-br':
            return 'pt-br'
        case 'zh':
            return 'zh-cn'
        case 'zh-tw':
            return 'zh-hant'
        case 'ko':
            return 'ko-kr'
        case 'ja':
            return 'ja-jp'
        case 'tr':
            return 'tr-tr'
        default:
            // not supported
            return 'en'
    }
}
